import {
    Autocomplete,
    TextField,
} from '@mui/material';
import {useState} from 'react';
const InputFormRow = (props) => {
    const {item, selectedValues, setSelectedValues} = props;
    const [showEmptyErr, toggleShowEmptyErr] = useState(false);
    const [showErrMsg, toggleShowErrMsg] = useState(false);
    const handleOnChange = (value) => {
        let temp = {...selectedValues};
        if(value && value != '') {
            temp[item.name] = value[item.refCols[0]];
            toggleShowEmptyErr(false);
        }

        else{
            delete temp[item.name];
            toggleShowEmptyErr(true);
        }
        setSelectedValues(temp);
    }

    const handleOnBlur = (event) => {
        if (!validateInput(event.target.value)) {
            toggleShowErrMsg(true);
        }

        else{
            toggleShowErrMsg(false);
            let temp = {...selectedValues};
            if (event.target.value !== '' && event.target.value) {
                temp[item.name] = event.target.value;
            }
            else{
                delete temp[item.name];
            }
            setSelectedValues(temp);
        }
    }

    const validateInput = (value) => {
        const type = item.colType;
        console.log('type:', type);
        switch (type) {
            case 'varchar':
                return true; //default is string/varchar
            case 'tinyint':
            case 'int':
                return /^-?\d*$/.test(value);
            case 'float':
                return /^-?\d*(?:\.\d+)?$/.test(value);
            default:
                return false
        }
    }
    return(
        <>
        <div className="InputForm-input">
            <h3 className= {item.name}>{item.displayName}</h3>
            {item.refCols.length == 0 ?
                <TextField
                    sx = {{
                    width: '100%'
                    }}
                    label=""
                    variant="standard"
                    onBlur={(event) => {handleOnBlur(event)}} 
                    defaultValue = {item.start}
                /> :
                <Autocomplete
                    options={item.options}
                    getOptionLabel={(option) => "" + option[item.refCols[1]]}
                    onChange={(event, value) => {handleOnChange(value)}}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label=""
                        variant="standard"
                    />
                    )}
                    defaultValue={item.options.find((a) => a[item.refCols[0]] === item.start) || null}
                    />
                }
                {showErrMsg && <small className = {`errorMsgFormat`}>Incorrect format, expected: {item.colType} <br></br></small>}
                {showEmptyErr && <small className = {`errorMsgEmpty`}>Field can't be empty</small>}
        </div>
        </>
    )
}

export default InputFormRow;