import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Dialog} from '@mui/material';
import Switch from '@mui/material/Switch';

export default function SwitchListSecondary(props) {
  const [checked, setChecked] = React.useState(new Array(props.cols.length).fill(true));

  const handleToggle = (index) => ()=> {
    const newChecked = [...checked];
    newChecked[index]=!newChecked[index];
    setChecked(newChecked);
    if (newChecked[index]){
      let newCols=[...props.filteredCols]
      newCols.splice(index, 0, props.cols[index])
      props.setFilteredCols(newCols)
    }
    else{
      let newCols=[...props.filteredCols]
      let field=props.cols[index].field;
      let i=newCols.findIndex(e => e.field===field);
      newCols.splice(i, 1);
      props.setFilteredCols(newCols);
    }
  };

  return (
    <Dialog
    maxWidth="sm"
    open={props.open}
    onClose={props.handleClose}
  >
      <List
        sx={{width: '100%', maxWidth: 360, bgcolor: '#87CEEB' }}
      >
      {
        props.cols.map((col, index) => (
          <ListItem key={index}>
            <ListItemText primary={col.headerName} />
            <Switch
              onChange={handleToggle(index)}
              checked={checked[index]}
            />
          </ListItem>)
        )
      }
      
    </List>
    </Dialog>
  );
}