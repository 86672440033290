import React, { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Button} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import apis from '../../../api';
import InputForm from "../InputForm";

export default function SpeciesInputForm(props) {
  const [selectedValues, setSelectedValues] = useState({});
  const [autofillOptions, setAutofillOptions] = useState([]);
  const [loading, setLoading] = useState(true)
  
  const navigate=useNavigate(); 

  const getSpeciesInputFormValues = async () => {
    try {
      const paramValue = new URLSearchParams(window.location.search).get("rowid");
      let newAutofillOptions = await apis.GetFormOptions({table: 'speciesnames', row: paramValue})
      console.log(newAutofillOptions)
      newAutofillOptions = newAutofillOptions.data.options;
      //check if url has rowid = # field. If it does, fill in entries
      
      if(paramValue){ //if updating
        const temp = {}
        //console.log(newautofillOptions)s
        for(const el of newAutofillOptions){
          // console.log(el)
          temp[el.name] = el.start
        }
        temp['smallestunitid'] = paramValue
        setSelectedValues(temp)
      }
      
      //make display names for the columns
      const array = [
        'Name',
        'Genus',
        'Species',
        'Sub Species',
        'Reference',
        'Date of creation',
        'Person First Created',
        'Date last modifies',
        'Person last modified',
      ];
      newAutofillOptions.map((item, index)=>{
        item.displayName = array[index]
      })

      setAutofillOptions(newAutofillOptions);
      setLoading(false)
      console.log(newAutofillOptions)
    } catch (error) {
      console.error("Error fetching distinct values:", error);
    }
  };
  useEffect(() =>{
    getSpeciesInputFormValues()
  }, [])
  
  const handleSubmit= async ()=>{
    let elements = Array.from(document.getElementsByClassName('input-error'))
    const target = document.getElementById('InputForm-submit')
    autofillOptions.forEach(el => {
      if(el.refCols.length != 0 || el.name === 'samedata'){
        const elementFK = document.getElementsByClassName(`${el.name}`)[0]
        if(selectedValues[el.name] == undefined || selectedValues[el.name] == null){
          elementFK.parentNode.classList.add('empty-error')
          elementFK.nextElementSibling.nextElementSibling.nextElementSibling.classList.add('show')
          // console.log("its empty")
        } 
        else{
          elementFK.parentNode.classList.remove('empty-error')
          elementFK.nextElementSibling.nextElementSibling.nextElementSibling.classList.remove('show')
        }
      }
    })
    
    const emptyFK = Array.from(document.getElementsByClassName('empty-error'))
    elements = elements.concat(emptyFK)
    elements.sort((a, b) => {
      const aRect = a.getBoundingClientRect();
      const bRect = b.getBoundingClientRect();
      return aRect.top - bRect.top;
    });
    
    let validEntry = (elements.length == 0) && (emptyFK.length == 0)

    if(validEntry){
      try{
        const result = await apis.SubmitMeasurement({measurements: selectedValues, table:'speciesnames'});
        console.log("submit success")
        console.log(result)
        target.classList.add('submit-success-bg')
        setTimeout(() => {
          target.classList.remove('submit-success-bg');
        }, 1200);
        console.log("Submitting values")
        console.log(selectedValues)
        return "Submission Success"
      }
      catch(e){
        console.log("submission failed, here is error")
        // console.log(e.response.data.error.sqlMessage)
        if(e.response.data.error.sqlMessage)
          return e.response.data.error.sqlMessage
        else
          return "Error but it's not an sql error"
      }

    }
    else{
      console.log("submit failure")
      target.classList.add('submit-error-bg')
      setTimeout(() => {
        target.classList.remove('submit-error-bg');
      }, 1200);
      console.log(elements)
      elements[0].scrollIntoView({behavior: "smooth", block: "center"})
    }
    return "Form errors exist!"
  }
  
  
  return (
    <>
    {
      <InputForm pageHeader = {"Species Input Form"} autofillOptions = {autofillOptions} selectedValues = {selectedValues} setSelectedValues = {setSelectedValues}
      handleSubmit = {handleSubmit} />
    }
    </>
  );  
}