import {
    TableRow,
    TableCell,
    Button,
    TextField,
    Select,
    MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
const UserRow = (props) => {
    const { row } = props;
    const [edit, toggleEdit] = useState(false);
    
    const handleChangeName = (event) => {
        const newName = event.target.value;
        if (newName !== row['Name']) {
            const payload = {
                field: 'fullname',
                username: row['Username'],
                newValue: newName
            }
            props.handleChangeUser(payload);
        }
        toggleEdit(false);
    }

    const handleChangeFunction = (event) => {
        const newFunction = event.target.value;
        if (newFunction !== row['Function']) {
            const payload = {
                field: 'function',
                username: row['Username'],
                newValue: newFunction
            }
            props.handleChangeUser(payload);
        }
    }

    const handleChangePrivilege = (event) => {
        const newPrivilege = event.target.value;
        if (newPrivilege !== row['Privilege']) {
            const payload = {
                field: 'privilege',
                username: row['Username'],
                newValue: newPrivilege
            }
            props.handleChangeUser(payload);
        }
    }
    return (
        <TableRow>
            <TableCell>
                {
                edit ?
                <TextField autoFocus variant='outlined' defaultValue={row['Name']} onBlur={handleChangeName}/> :
                <span>
                    { row['Username'] === props.user.email? row['Name'] + ' (You)' : row['Name'] }
                </span>
                
                }
                
                <Button onClick = {() => { toggleEdit(true); }}><EditIcon/></Button>
            </TableCell>
            <TableCell>
                <Select
                    defaultValue={row['Function']}
                    onChange={handleChangeFunction}
                >
                    <MenuItem value={null}>None</MenuItem>
                    <MenuItem value={'data entry'}>Data Entry</MenuItem>
                    <MenuItem value={'author'}>Author</MenuItem>
                    <MenuItem value={'proof reading'}>Proof Reading</MenuItem>
                    <MenuItem value={'root'}>root</MenuItem>
                </Select>
            </TableCell>
            <TableCell>
                {row['Username']}
            </TableCell>
            <TableCell>
                {row['Username'] === props.user.email? 
                    <span>{row['Privilege']}</span> :
                    <Select
                        defaultValue={row['Privilege']}
                        onChange={handleChangePrivilege}
                    >
                        <MenuItem value={'admin'}>Admin</MenuItem>
                        <MenuItem value={'user'}>User</MenuItem>
                    </Select>
                }
            </TableCell>
            <TableCell>
                {/* You can only delete a user  */}
                {row['Username'] !== props.user.email ? 
                    <Button onClick = {() => props.handleOpenConfirm(row['Username'])}><DeleteIcon/></Button> :
                    <></>
                }
            </TableCell>
        </TableRow>
    );
}

export default UserRow;