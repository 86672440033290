import './App.css';
import {Login} from './Pages/Login';
import UserGuide from './Pages/UserGuide';
import {Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import { MeasurementInputForm, SourcesInputForm, SpeciesInputForm, DatasetInputForm } from './Pages/InputForm';
import { DatasetView, SpeciesView, Measurements, Sources, GeneralUserView, Locations, Vernacular, Survival } from './Pages/Views';
import { HomeLayout, InputFormLayout } from './components/Layouts';
import { useContext } from 'react';
import AdminDashboard from './Pages/AdminDashboard/AdminDashboard';
import AuthContext from './store/auth-context';

function App() {
  const ctx = useContext(AuthContext);
  return (
      <>
        <Routes>
          <Route path='/' element={ ctx.user? <HomeLayout/> : <Login/>}>
            <Route index element = { <Home user={ ctx.user } /> }/>
            <Route path='general-user' element={ <GeneralUserView/> }/>
            <Route path='measurements' element={ <Measurements user={ctx.user}/> }/>
            <Route path='sources' element={ <Sources/> }/>
            <Route path='datasets' element={ <DatasetView/> }/>
            <Route path='species' element={ <SpeciesView/> }/>
            <Route path='locations' element={ <Locations/> }/>
            <Route path='vernacular' element={ <Vernacular/> }/>
            <Route path='survival' element={ <Survival/> }/>
            <Route path='admindashboard' element={<AdminDashboard user={ ctx.user }/>}/>
            <Route path='user-guide' element={ <UserGuide/> }/>
          </Route>
          <Route path='/input' element={ ctx.user? <InputFormLayout/> : <Login/>}>
            <Route path='measurements' element={<MeasurementInputForm/>}/>
            <Route path='sources' element={<SourcesInputForm/>}/>
            <Route path='speciesview' element={<SpeciesInputForm/>}/>
            <Route path='datasetview' element={<DatasetInputForm/>}/> 
          </Route>
        </Routes>
      </>
  );
}

export default App;