import ButtonAppBar from "../AppBar/ButtonAppBar";
import { Outlet } from 'react-router-dom';
import { PageContextProvider } from "../../store/page-context";
import { FiltersContextProvider } from "../../store/filters-context";
const HomeLayout = (props) => {
    return(
        <PageContextProvider>
            <FiltersContextProvider>
                <ButtonAppBar/>
                <Outlet/>
            </FiltersContextProvider>
        </PageContextProvider>
    )
}
export default HomeLayout;