import TableView from './TableView/TableView';
import {
    Button,
    Stack
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterDialog from './FilterDialog/FilterDialog';
import { useState, useEffect, useContext } from 'react';
import { CSVLink } from "react-csv";
import ColumnVisibilitySelector from './ColumnVisibility/ColumnVisibilitySelector';
import {useLocation} from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import PageContext from '../../store/page-context';
import FiltersContext from '../../store/filters-context';
import filterColumns from './FilterColumns.json';
const PageView = (props) => {
    const location = useLocation();

    const [open, setOpen]=useState(false);
    const [rows, setRows]=useState([])
    const [filteredRows, setFilteredRows] = useState([]);

    const [cols, setCols]=useState([])
    const [filteredCols, setFilteredCols]=useState([]);
    const [filters, setFilters]=useState([]);

    const [showColumnSelector, toggleShowColumnSelector]=useState(false);
    const ctx = useContext(AuthContext);

    const pageContext = useContext(PageContext);
    const page = pageContext.page;

    const filtersContext= useContext(FiltersContext);

    useEffect(() => {
        if(filtersContext.filters.length > 0){
            setFilters(filtersContext.filters);
        }
        props.func().then(res => {
            let tempRows = res.data.rows;
            setRows(tempRows);
            setFilteredRows(tempRows);
            if(res.data.rows){
                let obj=res.data.rows[0];
                let temp=[]
                for (let key in obj){
                    temp.push({field:key, headerName: key, type: typeof obj[key], width:'200'});
                }
                setCols(temp)
                setFilteredCols(temp);
            }
        });
    }, []);

    useEffect(() => {
        if (location.state?.reference){
            const tempFilters=[];
            if(props.view==='datasets'){
                tempFilters.push({
                    column: 'Reference Short',
                    operator: 'contains',
                    value: location.state.reference,
                })
            }
            else if (props.view === 'measurements') {
                tempFilters.push({
                    column: 'Dataset',
                    operator: 'contains',
                    value: location.state.reference,
                })
            }
            setFilters(tempFilters);
        }
    }, [location.state?.reference]);

    useEffect(() => {
        filterRows();
    }, [filters, rows]);

    const filterRows = () => {
        let temp=[...rows];
        for(let filter of filters){
            let column=filter.column;
            let operator=filter.operator;
            let value=filter.value;
            if (value !== '') {
                if (operator === 'is exactly') {
                    temp = temp.filter(e => e[column]?.toUpperCase() === value.toUpperCase());
                }
                else if(operator==='contains'){
                    temp=temp.filter(e =>  e[column]?.toUpperCase().includes(value.toUpperCase()));
                }
                else{
                    let expr=operator+value;
                    temp=temp.filter(e => eval(e[column]+expr));
                }
            }
        }
        setFilteredRows(temp);
    }

    const download = () => {
        const data = filteredRows;
        const data_to_download=[];
        const columns = filteredCols.map(e => e.field);
        data_to_download.push(columns);
        
        for(let item of data){
            let row = [];
            for(let column of columns) {
                let e = item[column];
                if (typeof e === 'string') {
                    e = e.replace(/"/g, '""')
                    e = e.replace(/(\r\n|\n|\r)/gm, "")
                }
                row.push(e);
            }
            data_to_download.push(row);
        }
        return data_to_download;
    }

    return (
    <div className='view-container'>
        <Stack className = "toolbar" direction="row" space={1} sx = {{display: 'flex', justifyContent: "space-between", padding: "0 1rem"}}>
            <div>
                <CSVLink data={download()} style={{ textDecoration: 'none' }}><Button><FileDownloadIcon/>EXPORT</Button></CSVLink>
                <Button onClick={()=>{setOpen(true);}}startIcon={<FilterListIcon/>}>FILTERS</Button>
                <Button startIcon={<ViewColumnIcon/>} onClick={()=>{toggleShowColumnSelector(!showColumnSelector)}}>SHOW/HIDE COLUMNS</Button>
            </div>
            {ctx.user.privilege == "admin" && props.inputBar} 
        </Stack>
        
        <TableView view={props.view} 
            className = 'view-content' 
            rows={filteredRows} 
            setRows={setRows} 
            cols={filteredCols} 
            user = {ctx.user}
            func = {props.func} 
            deleted = {props.deleted}
            fullRows = {rows}
        />
        {cols.length>0 && <ColumnVisibilitySelector 
                                    setFilteredCols={setFilteredCols}
                                    cols={cols}
                                    filteredCols={filteredCols} 
                                    handleClose={()=>{toggleShowColumnSelector(false)}} 
                                    open={showColumnSelector}/>}
        <FilterDialog 
            rows={rows}
            open={open}
            setOpen={setOpen} 
            cols={cols.filter(col => filterColumns[page].includes(col.headerName))}
            filters={filters} 
            setFilters={setFilters}
        />
        
    </div>
    )
}

export default PageView;
