import { useContext } from 'react';
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Button,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import PageContext from '../../store/page-context';
export default function ButtonAppBar(props) {
    const ctx = useContext(AuthContext);
    const pageContext = useContext(PageContext);
    const page = pageContext.page;
    const buttonStyles = {
        color: 'white',
        transition: 'all .4s ease',
        fontFamily: 'Helvetica, serif',
        fontSize: '1rem',
        textTransform: 'none',
        backgroundColor: 'rgba(255,255,255,.15)',
        '&:hover': {
          transform: 'scale(1.07)',
          transition: 'all .4s ease',
          cursor: 'pointer',
        }
    }

    return (
        <Box>
        <AppBar position="static" sx = {{display: "flex"}}>
            <Toolbar className="buttonAppBar-container" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%'}}>
                <Link to="/">
                    <Button sx={buttonStyles}>
                        <HomeIcon />
                    </Button>
                </Link>
                <Typography variant="h6" component="div" sx={{textAlign: 'center' }}>
                    { page }
                </Typography>
                <div className="toolbar-right">
                    <Typography variant="h6" component="div">
                    {ctx.user.fullname}
                    {ctx.user.privilege === "admin" && " (admin)"}
                    </Typography>
                    <Link to="/">
                        <Button sx = {buttonStyles} onClick={ ctx.handleLogout } >Logout</Button>
                    </Link>

                </div>
            </Toolbar>
        </AppBar>
    </Box>
    );
}