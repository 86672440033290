import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import apis from '../../../api';
import InputForm from "../InputForm";

export default function MeasurementInputForm(props) {
  const [selectedValues, setSelectedValues] = useState({});
  const [autofillOptions, setAutofillOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const displayNameMapping = {
    datasetfk: 'Dataset*',
    traitfk: 'Trait*',
    sexorparityfk: 'Sex or Parity*',
    survivalconditionsfk: 'Survival Conditions*',
    survivallandmarksfk: 'Survival Landmarks*',
    samedata: 'Same Data*',
    samplesize: 'Sample Size',
    samplesizeforfk: 'Sample Size For*',
    descriptivestatisticsfk: 'Descriptive Statistics*',
    measurement: 'Measurement',
    dimensioncalculatedbol: 'Dimension Calculated Bol',
    valuecalculatedbol: 'Value Calculated Bol',
    containsestimatesbol: 'Contains Estimates Bol',
    containsrightcensoredbol: 'Contains Right Censored Bol',
    containsleftcensoredbol: 'Contains Left Censored Bol',
    containscensoredbol: 'Contains Censored Bol',
    containsindividualvaluesbol: 'Contains Individual Values Bol',
    containsgroupspecificvaluesbol: 'Contains Group Specific Values Bol',
    pagenumber: 'Page Number',
    tablenumber: 'Table Number',
    figurenumber: 'Figure Number',
    commentsmeasurement: 'Comments Measurement',
    conceptionsfk: 'Conception Method*'
  }

  const getMeasurementFormValues = async () => {
    try {
      const paramValue = new URLSearchParams(window.location.search).get("rowid");
      let newAutofillOptions = await apis.GetFormOptions({table: 'measurements', row: paramValue});
      newAutofillOptions = newAutofillOptions.data.options;
      //check if url has rowid = # field. If it does, fill in entries
      newAutofillOptions = newAutofillOptions.map(option => ({displayName: displayNameMapping[option.name], ...option}));
      if ( paramValue ){ //if updating
        const temp = {}
        for(const el of newAutofillOptions){
          temp[el.name] = el.start;
        }
        temp['smallestunitid'] = paramValue;
        setSelectedValues(temp);
      }
      
      setAutofillOptions(newAutofillOptions);
    } catch (error) {
      console.error("Error fetching distinct values:", error);
    }
  };
  
  useEffect(() =>{
    setLoading(true);
    getMeasurementFormValues();
    setLoading(false);
  }, []);

  const handleSubmit = async () => {
    const res = await apis.SubmitMeasurement({measurements: selectedValues, table:'measurements'});
    if (res.status === 200) {
      navigate('/measurements');
      return 'Submission Success';
    }
    else{
      return 'ERROR: Submission Failed';
    }
    
  }
  
  
  return (
    <>
      <InputForm 
        pageHeader = {"Measurement Input Form"} 
        autofillOptions = {autofillOptions} 
        selectedValues = {selectedValues}
        setSelectedValues = {setSelectedValues}
        handleSubmit = {handleSubmit} />
    </>
  );  
}

