import React from 'react'
import PageView from '../../components/Home/PageView'
import apis from '../../api'

const Survival = (props) => {
  return (
        <PageView view={'survival'} func={apis.GetSurvival}/>
  )
}

export default Survival