import axios from 'axios';

// axios.defaults.baseURL='http://localhost:3000';
axios.defaults.baseURL='http://45.56.107.82:3000';
axios.defaults.withCredentials = true;
/*USER ROUTES*/
const Register = (payload) => axios.post('/user/register', payload);
const AdminRegister=(payload) => axios.post('/user/adminregister', payload);
const AdminDelete=(payload) => axios.post('/user/admindelete', payload);
const Login = (payload) => axios.post('/user/login', payload);
const ChangeUser = (payload) => axios.post('/user/change', payload);
const ChangePassword = (payload) => axios.post('/user/change-password', payload);
const GetUser = () => axios.get('/user/get');
const Logout= () => axios.post('/user/logout');


/*VIEW ROUTES*/
const GetMeasurementsView = (payload) => axios.get('/view/measurementsview', {params: payload});
const GetSources = () => axios.get('/view/sourcesview');
const GetDataSets=()=>axios.get('view/datasetview');
const GetSpecies=()=>axios.get('/view/speciesview');
const GetGeneralUserView = () => axios.get('/view/general-user-view');
const GetLocations = () => axios.get('/view/locations')
const GetVernacular = () => axios.get('/view/vernacular')
const GetSurvival = () => axios.get('/view/survival')
const GetAdmin = () => axios.get('/view/admin')

const SubmitMeasurement=(payload)=>axios.put('/view/submitmeasurement', payload);
//addsource
const DeleteRow=(payload)=>axios.delete('/view/deleterow' + payload)
const GetFormOptions = (payload) => axios.post('/view/getformoptions', payload)
const ToggleProofread=(payload)=>axios.post('/view/toggleproofread', payload);

const apis={
    Login,
    Register,
    AdminRegister,
    AdminDelete,
    GetMeasurementsView,
    GetSources,
    GetDataSets,
    GetSpecies,
    GetLocations,
    GetVernacular,
    GetSurvival,
    GetAdmin,
    GetUser,
    GetGeneralUserView,
    GetFormOptions,
    Logout,
    SubmitMeasurement,
    ToggleProofread,
    DeleteRow,
    ChangeUser,
    ChangePassword
};
export default apis;