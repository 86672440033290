import PageView from '../../components/Home/PageView'
import apis from '../../api';
import {useLocation} from 'react-router-dom';
const GeneralUserView = (props) => {
    const location = useLocation();
    const filters = location.state?.filters;
    console.log(filters);
    return(
        <PageView filters={filters} func={apis.GetGeneralUserView}/>
    )
}
export default GeneralUserView;