import PageView from '../../components/Home/PageView'
import apis from '../../api';
import { useState, createRef } from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const Measurements = (props) => {
    const [openUpdate, setOpenUpdate] = useState(false); 
    const [openDelete, setOpenDelete] = useState(false); 
    const [inputVal, setInputVal] = useState(0);
    const [deleted, setDeleted] = useState(null);
    
    
    const handleOpenDelete = () => {
        setOpenDelete(true);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    }

    const handleDelete = async () => {
        setOpenDelete(false);
        await apis.DeleteRow(`?id=${inputVal}&table=measurements`)
        setDeleted(inputVal)
    }

    const handleOpenUpdate = () => {
        setOpenUpdate(true);
    }
    const handleCloseUpdate = () => {
        setOpenUpdate(false);
    }
    
    const handleUpdate = () => {
        setOpenUpdate(false);
        console.log(inputVal)
        window.open("/input/measurements?rowid=" + inputVal, "_blank")
    }

    const inputBar= <div className="inputbar">
        <Button onClick = {handleOpenDelete}>Delete</Button>
        <Dialog open = {openDelete} onClose={handleCloseDelete}>
            <DialogTitle>Enter id for deletion</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    onBlur = {(event)=>setInputVal(event.target.value)}
                    margin="dense"
                    id="measurement-update-id-form"
                    label="Data ID"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDelete}>Cancel</Button>
                <Button onClick={handleDelete}>Delete</Button>
            </DialogActions>
        </Dialog>

        
        <Button onClick = {handleOpenUpdate}>Update</Button>
        <Dialog open = {openUpdate} onClose={handleCloseUpdate}>
            <DialogTitle>Enter id for updation</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    onBlur = {(event)=>setInputVal(event.target.value)}
                    margin="dense"
                    id="measurement-update-id-form"
                    label="Data ID"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseUpdate}>Cancel</Button>
                <Button onClick={handleUpdate}>Update</Button>
            </DialogActions>
        </Dialog>

        <Button onClick = {() =>{window.open("input/measurements")}}>insert</Button>
    </div>
    return (
        <>
            <PageView view={'measurements'} deleted = {deleted} inputBar = {inputBar} func={apis.GetMeasurementsView}/> 
        </>
    )
}
export default Measurements;
