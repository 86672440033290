import { useState, createContext, useEffect } from 'react';
import apis from '../api';

const AuthContext = createContext({
    user: null,
    setUser: () => {},
    handleLogin: (email, password) => {},
    handleLogout: () => {}
});

export const AuthContextProvider = (props) => {
    const[user, setUser] = useState(null);

    const handleLogin = (email, password) => {
        apis.Login({email, password}).then(res => {
            if (res.data.user) {
                setUser(res.data.user);
            }
        })
    }

    const handleLogout = () => {
        apis.Logout().then(res => {
            setUser(null);
        });
    }
    useEffect(() => {
        apis.GetUser().then(res => {
            setUser(res.data.user);
        }).catch(e => {
            console.log(e);
        });
    }, []);
    
    return(
        <AuthContext.Provider
            value = {{
                user: user,
                setUser: setUser,
                handleLogin: handleLogin,
                handleLogout: handleLogout,
            }}
        >
            { props.children }
        </AuthContext.Provider>
    )
}

export default AuthContext;