import PageView from '../../components/Home/PageView'
import apis from '../../api';

const SpeciesView = (props) => {
  return (
    // no inputbar
    <PageView func={apis.GetSpecies} />
  )
}

export default SpeciesView