import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import apis from '../api';
import Grid from '@mui/material/Grid';

const Home = (props) => {

  
  return (
    <div className='home'>
      <Grid container direction='row' spacing={2}>
          <Grid item xs={4}>
          <div className='home-links'>
            <h1 style={{color:'white'}}>About This Database</h1>
          
            <Link to="/sources" target="_blank">
              <button className='button'>References</button>
            </Link>
            <Link to="/species" target="_blank">
              <button className='button'>Sub Species</button>
            </Link>
            <Link to="/datasets" target="_blank">
              <button className='button'>Datasets</button>
            </Link>
            <Link to="/locations" target="_blank">
              <button className='button'>Locations</button>
            </Link>
            <Link to="/vernacular" target="_blank">
              <button className='button'>Vernacular Names</button>
            </Link>
          </div>
          </Grid>
          <Grid item xs={4}>
            <div className='home-links'>
              <h1>How to Use This Database?</h1>
              <Link to="/user-guide" target="_blank"> 
                <button className='button'>User Guide</button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={4}>
          <div className='home-links'>
              <h1>Access Database</h1>
              <Link to="/general-user" target="_blank">
                <button style={{ backgroundColor: "#27CF33" }} className='button'>Primate Life History Data</button>
              </Link>
              <Link to="/measurements" target="_blank">
                <button style={{ backgroundColor: "#27CF33" }} className='button'>Measurements</button>
              </Link>
              {props.user.privilege === 'admin' && (
                <Link to='/admindashboard' target="_blank">
                  <button className='button' style={{ backgroundColor: "#1976d2" }}> Admin Dashboard</button>
                </Link>
              )}
            </div>
          </Grid>
        </Grid>
    </div>
  );
};

export default Home;
