import React from 'react';
import { Link } from 'react-router-dom';

const UserGuide = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const buttonStyle = {
    margin: '10px 0',
  };

  return (
    <div style={containerStyle}>
      <h1>User Guide</h1>
      <div>
        This page serves as the place holder for instructions on how to use this database.
      </div>
      {/* <Link to="/locations" target="_blank">
        <button className='button' style={buttonStyle}>Locations</button>
      </Link>

      <Link to="/vernacular" target="_blank">
        <button className='button' style={buttonStyle}>Vernacular Names</button>
      </Link> */}
    </div>
  );
};

export default UserGuide;
