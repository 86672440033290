import { useState, useEffect }from 'react';
import {
    TableCell,
    TableRow,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    TextField,
    Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import mapping from './autoCompleteMapping.json';
const FilterDialogRow = (props)=> {
    const [operators, setOperators] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let col = props.filter.column;
        let obj = props.cols.find(e => e.field === col);
        if (mapping.autoComplete.includes(col)) {
            let newOptions = [...new Set(props.rows.map(e => e[col]))].sort();
            setOperators(['is exactly', 'contains']);
            setOptions(newOptions);
        }
        else if (mapping.boolean.includes(col) || col.includes('Bol')) {
            let newOptions = ['Yes','No'];
            setOperators(['is exactly']);
            setOptions(newOptions);
        }

        else{
            if(obj.type === 'string' || obj.type === 'object') {
                setOperators(['is exactly', 'contains']);
            }
            else if(obj.type==='number' && col !== 'Same Data') {
                setOperators(['==', '>', '<', '>=', '<=']);
            }
            else {
                setOperators(['==']);
            }
            setOptions([]);
        }
    }, [props.cols, props.filter.column]);

    const handleChangeCol = (event) => {
        let obj = props.cols.find(e => e.field === event.target.value);

        let idx = props.index;
        let temp = [...props.filters];
        temp[idx].column = event.target.value;

        if (obj.type === 'string' || obj.type === 'object'){
            temp[idx].operator = 'is exactly';
        }

        else if(obj.type === 'number'){
            temp[idx].operator = '==';
            temp[idx].value = '';
        }
        props.setFilters(temp);
    }

    const handleChangeOp = (event) => {
        let idx = props.index;
        let temp = [...props.filters];
        temp[idx].operator = event.target.value;
        props.setFilters(temp);
    }

    const handleChangeValParent = (event) => {
        let idx=props.index;
        let temp=[...props.filters];
        temp[idx].value=event.target.value;
        props.setFilters(temp);
    }

    const handleDelete = (event) => {
        let idx=props.index;
        let temp=[...props.filters];
        temp.splice(idx, 1);
        props.setFilters(temp);
    }

    return(
        <TableRow className='filterdialogue-row'>
            <TableCell ><Button onClick={handleDelete} startIcon={<DeleteIcon/>}/></TableCell>
            <div className='filterdialogue-fields'>
                <TableCell>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">column</InputLabel>
                        <Select
                        label="Column"
                        value={props.filter.column}
                        onChange={handleChangeCol}
                        >
                            {props.cols.map((col, index)=>(
                                <MenuItem key={index} value={col.field}>{col.field}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">operator</InputLabel>
                        <Select
                            label="Operator"
                            value={props.filter.operator}
                            // defaultValue = {operators[0]}
                            onChange={handleChangeOp}
                        >
                            {operators.map((op, index)=>(
                                <MenuItem key={index} value={op}>{op}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell>
                    {
                        options.length && props.filter.operator === 'is exactly' ?
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">value</InputLabel>
                            <Select
                                label="Value"
                                value={props.filter.value}
                                onChange={handleChangeValParent}
                                >
                                {options.map((val, index)=>(
                                    <MenuItem key={index} value={val}>{val}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>:
                        <TextField 
                            fullWidth 
                            defaultValue={props.filter.value} 
                            label="value" 
                            onBlur={handleChangeValParent}
                        />
                    }
                    </TableCell>
                    
            </div>
        </TableRow>
    )
}

export default FilterDialogRow;