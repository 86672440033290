import React, {useState} from "react";
import { Button} from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import './InputForm.css';
import InputFormRow from './InputFormRow';
/**
 * props to be passed: autofillOptions, key, pageHeader, selectedValues, handleSubmit
 *  autofillOptions is the array of options for all the columns in the database. This should be acquired through the apis.GetFormOptions() server call
 *  key is the same as the autofillOptions array. It's just there to trigger the component render once autofillOptions has been populated. (Because filling the table is an async method, we don't want to immediately pass a null value to InputForm)
 *  selectedValues is the json object of all the selected options. The updating is all done in this inputForm component. You'll have to initialize it in the parent because handleSubmit will need to access selectedValues
 *  handleSubmit is a function that is responsible for submitting. We left it to the parent because there may be some extra input verifying that is specific to the different pages, and may require different implementation
 */ 

export default function InputForm(props) {
    const [submissionStatus, setSubmissionStatus] = useState("");
    //Styling
    const buttonStyles = {
        backgroundColor: 'azure',
        color: 'midnightblue',
        transition: 'all .4s ease',
        fontFamily: 'Helvetica, serif',
        boxShadow: '0 0 .5rem silver',
        '&:hover': {
        backgroundColor: 'lightblue',
        transform: 'scale(1.07)',
        transition: 'all .4s ease',
        cursor: 'pointer',
        }
    }

    const handleSubmit = async () =>{
        const required = props.autofillOptions.filter(e => e.displayName.includes('*'));
        for (let field of required) {
            if(!props.selectedValues[field.name]) {
                console.log(field.name, props.selectedValues);
                setSubmissionStatus('ERROR: Please fill in all the required fields');
                return;
            }
        }
        const result = await props.handleSubmit();
        if (result === 'Submission Failed') {
            setSubmissionStatus(result);
        }
    }

    return (
        <div className="InputForm">
                <div className="InputForm-header">
                    <h3>{props.pageHeader}</h3>
                    <h5 className = 'InputForm-submitMessage'> {submissionStatus}</h5>
                    <div className = "InputForm-actions">
                        <Button id = "InputForm-submit" onClick={handleSubmit} sx={buttonStyles} >submit</Button>
                        <Link to="/" className = 'InputForm-homeLink'><Button sx = {buttonStyles}><HomeIcon/></Button></Link>
                    </div>
                </div>
                {props.autofillOptions.length === 0? <h3>Loading...</h3> :
                    <div className="InputForm-content">
                        {props.autofillOptions.map((item, index) => (
                            <InputFormRow
                                key={index}
                                item={item}
                                setSelectedValues={props.setSelectedValues}
                                selectedValues={props.selectedValues}
                            /> 
                        ))}
                            
                    </div>
                }

        </div>
    );  
}
