import {
    useState, 
    useEffect, 
    createContext,
} from 'react';
import {useLocation} from 'react-router-dom';
import mapping from '../../src/resources/viewTitleMapping.json';
const PageContext = createContext({
    page: null,
    setPage: (page) => {}
});

export const PageContextProvider = (props) => {
    const[page, setPage] = useState('Welcome!');
    const location = useLocation();
    
    useEffect(() => {
        const name = location.pathname.split('/')[1];
        const page = mapping[name]? mapping[name] : 'Welcome!';
        setPage(page);
    }, [location.pathname]);

    return(
        <PageContext.Provider
            value = {{
                page: page,
                setPage: setPage,
            }}
        >
            { props.children }
        </PageContext.Provider>
    )
}
export default PageContext;