/**
 * This context is mainly used for the top level filter for primate life history data
 */
import {
    useState,
    useEffect,
    createContext,
} from 'react';

const FiltersContext = createContext({
    filters: [],
    setFilters: (filters) => {}
});

export const FiltersContextProvider = (props) => {
    const [filters, setFilters] = useState([]);
    return (
        <FiltersContext.Provider
            value = {{
                filters: filters,
                setFilters: setFilters,
            }}
        >
            {props.children}
        </FiltersContext.Provider>
    )
}

export default FiltersContext;