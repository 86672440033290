import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import '../../../style.scss';
import apis from '../../../api';
import { Button } from '@mui/material';
import {useNavigate} from 'react-router-dom';

const MIN_WIDTH=10;
const MAX_WIDTH=400;
export default function TableView(props) {
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(25);
  const [orderBy, setOrderBy]=React.useState('id');
  const [order, setOrder]=React.useState('asc');

  const navigate = useNavigate();

  const columnRefs=props.cols.map(() => React.createRef());
  const isResizing=React.useRef(-1);

  React.useEffect(()=>{
    document.onmousemove=handleOnMouseMove;
    document.onmouseup=handleOnMouseUp;
    return()=>{
      document.onmousemove=null;
      document.onmouseup=null;
    }
  })
  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const onClickResizeColumn=(index)=>{
    console.log("start resize");
    isResizing.current=index;
  }

  const handleOnMouseMove=(e)=>{
    if(isResizing.current>=0){
      const newWidth=e.clientX-columnRefs[isResizing.current].current.parentElement?.getBoundingClientRect().left;
      adjustWidthColumn(isResizing.current, newWidth);
    }
  }

  const adjustWidthColumn=(index, width)=>{
    const newWidth=width>MAX_WIDTH ? MAX_WIDTH : width<MIN_WIDTH ? MIN_WIDTH : width;
    console.log("old width:", columnRefs[index].current.parentElement.style.width, "new width", newWidth);
    columnRefs[index].current.parentElement.style.width=newWidth+"px";
  }

  const handleOnMouseUp=()=>{
    isResizing.current=-1;
  }

  const handleSortLabelClick = (col) => {
    console.log('sorting by ', col.field);
    let property = col.field;
    const temp = [...props.fullRows]
    if(order==='asc'){
      if (property === 'Measurement IDs') {
        temp.sort((a, b) => {
          let ids_a = a[property].split(', ');
          let ids_b = b[property].split(', ');

          a = parseInt(ids_a[0])
          b = parseInt(ids_b[0])
          
          return a > b ? 1 : (a < b ? -1 : 0);
        });
      }
      else{
        temp.sort((a,b) => (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0));
      }
      
    }
    else if(order==='desc'){
      if (property === 'Measurement IDs') {
        temp.sort((a, b) => {
          let ids_a = a[property].split(', ');
          let ids_b = b[property].split(', ');

          a = parseInt(ids_a[0])
          b = parseInt(ids_b[0])
          
          return a < b ? 1 : (a > b ? -1 : 0);
        });
      }
      else{
        temp.sort((a,b) => (a[property] > b[property]) ? -1 : ((b[property] > a[property]) ? 1 : 0));
      }
      
    }
    props.setRows(temp);
    const isAsc=orderBy===col.field&&order==='asc';
    setOrder(isAsc?'desc':'asc');
    setOrderBy(col.field);
  }

  const toggleProofRead= async(rowid, isProofread)=>{
    const date = new Date().toISOString().split('T')[0];
    const result = await apis.ToggleProofread({ rowid, isProofread, date})

    // console.log(props.rows)
    if(result.status == 200){
      const temp = [...props.fullRows]
      const row = temp.find(row => row['Smallest Unit ID'] === rowid)
      if (row['Proofread Status'] === 'Yes'){
        row['Person Proofread'] = null
        row['Date Proofread'] = null 
        row['Proofread Status'] = 'No';
      }
      else{
        row['Person Proofread'] = props.user.fullname;
        row['Date Proofread'] = date;
        row['Proofread Status'] = 'Yes';
      }
      props.setRows(temp)
    }
  }

  const handleOnClickRow = (row) => {
    if(props.view==='source'){
      navigate('/datasets', {state: {reference: row['Reference Short']}});
    }
    else if (props.view==='datasets') {
      navigate('/measurements', {state: {reference: row['Reference Short']+'_'+row['Species Name in Reference']}});
      //console.log(row['Dataset ID']+'_'+row['Reference Short']+'_'+row['Species Name in Reference'])
    }
  }

  React.useEffect(()=>{
    if(props.deleted){
      props.setRows(props.fullRows.filter(e=> e['Smallest Unit ID'] != props.deleted))
    }
  }, props.deleted)

  return(
    <Paper>
        <TableContainer sx={{maxHeight: "85vh"}}>
          <Table stickyHeader={true} aria-label="sticky table" className="table">
            <TableHead className="tableHead">
              <TableRow>
                {
                  props.cols.map((col, colIdx) => (
                    <TableCell
                      key= {colIdx}
                      align="center"
                      className="tableCell resizable header"
                      >
                      {col.field}
                      <TableSortLabel
                        active={orderBy === col.field}
                        // active = {true}
                        direction={orderBy===col.field ? order:'asc'}
                        onClick={()=>{handleSortLabelClick(col)}}
                      >
                      </TableSortLabel>
                      <div
                        
                        className={"resizeLine"}
                        ref={columnRefs[colIdx]}
                        onMouseDown={()=>onClickResizeColumn(colIdx)}
                      >
                      </div>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
            {props.rows.slice(pg * rpg, pg*rpg + rpg).map((row, index) => (
                <TableRow 
                  onDoubleClick={e => handleOnClickRow(row)} 
                  sx={{backgroundColor: index%2==0? '#fbfbff':''}}
                  key={index}>
                  {props.cols.map((col, idx) => (
                    <TableCell
                      key={idx}
                      align="center"
                      className="tableCell resizable"
                      sx = {{backgroundColor: col.field === 'Survival Option' || col.field === 'Survival Landmark' ? '#A9A9A9' : ''}}
                    >
                    {/* Button edge case for measurement field */}
                    {col.field === "Proofread Status"? 
                      (
                        <Button 
                          onClick = { ()=> toggleProofRead(row['Smallest Unit ID'], row[col.field] === 'Yes' ) }
                          sx = {{textTransform: 'none', color: row[col.field] === 'Yes'? 'lime' : 'red'}}
                        >{row[col.field]}</Button>
                      )
                    :
                      row[col.field] 
                    }
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={props.rows.length}
              rowsPerPage={rpg}
              page={pg}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
      </Paper>
  )
}

