import React, { useEffect, useState } from 'react'
import apis from '../../api'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableContainer,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserRow from './UserRow';
import api from '../../api';
const AdminDashboard = (props) => {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [openConfirm, setOpenConfirm] = useState(false); 
    const [deleteId, setDeleteId] = useState(-1);
    const [updatePwdMsg, setUpdatePwdMsg] = useState('');
    const [updatePwd, setUpdatePwd] = useState({});

    const [registerNewUser, setRegisterNewUser] = useState({privilege: 'user'});
    const [registerNewUserMsg, setRegisterNewUserMsg] = useState('');
    
    const getUsers = async() => {
        console.log("admin dashboard:", props.user);
        let res = await apis.GetAdmin()
        res = res.data.rows
        setRows(res)
        setColumns(Object.keys(res[0]));
      }

    useEffect( () => {
      getUsers();
    }, []);

    const handleRegister = async () => {
      const{fullname, email, password, confirmPassword, privilege} = registerNewUser;
      if(fullname === undefined || email === undefined || password === undefined || !confirmPassword){
        setRegisterNewUserMsg('ERROR: Please fill out all the required fields');
        return;
      }
      
      if(password !== confirmPassword) {
        setRegisterNewUserMsg('ERROR: passwords don\'t match');
        return;
      }
      try{
        const res = await apis.AdminRegister({fullname, email, password, privilege});
        if (res.status === 200){
          setRegisterNewUserMsg(`User ${fullname} is registered successfully`);
        }
      }
      catch (err) {
        setRegisterNewUserMsg(err.response.data.message);
      }
      
      getUsers();
    }

    const handleDelete = async () => {
        await apis.AdminDelete({id: deleteId});
        handleCloseConfirm();
        getUsers();
    }

    const handleOpenConfirm = (id) => {
      setDeleteId(id);
      setOpenConfirm(true);
    }

    const handleCloseConfirm = () => {
      setDeleteId(-1)
      setOpenConfirm(false);
    }

    const handleChangeUser = async (payload) => {
      await api.ChangeUser(payload);
      await getUsers();
    }

    const handleUpdatePassword = async () => {
      let {email, newPassword, confirmPassword} = updatePwd;
      console.log(email, newPassword, confirmPassword);
      if (!email || !newPassword || !confirmPassword) {
        setUpdatePwdMsg('ERROR: Please enter all the required fields');
      }
      else if (newPassword !== confirmPassword) {
        setUpdatePwdMsg('ERROR: passwords don\'t match');
      }
      else{
        let res;
        try {
          res = await api.ChangePassword({email: email, newPassword: newPassword});
          if (res.status === 200) {
            setUpdatePwdMsg('password is updated successfully');
          }
        }

        catch (err) {
          setUpdatePwdMsg(err.response.data.message);
        }
        
      }
    }
  
  if (props.user.privilege !== 'admin') {
    return (
      <div style={{color: 'white'}}>
        Unauthorized!
      </div>
    )
  }
  return (
        <div className='admindashboard'>
            <div>
              <h3>Create Users: </h3>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>New user form</AccordionSummary>
                    <AccordionDetails sx = {{display: "grid", gridTemplateColumns: "repeat(3,1fr)", gap: "1rem"}}>
                        <TextField
                          required 
                          id = 'fullname'
                          label = 'Full Name'
                          onChange = {(event) => {setRegisterNewUser({...registerNewUser, fullname: event.target.value})}}
                          />
                        <TextField
                          required 
                          id = 'email'
                          label = 'Email'
                          onChange = {(event) => {setRegisterNewUser({...registerNewUser, email: event.target.value})}}
                          />
                        <TextField
                          required 
                          type = 'password'
                          id = 'password'
                          label = 'Password'
                          onChange = {(event) => {setRegisterNewUser({...registerNewUser, password: event.target.value})}}
                          />
                        <TextField
                          required 
                          type = 'password'
                          id = 'confirm password'
                          label = 'Confirm Password'
                          onChange = {(event) => {setRegisterNewUser({...registerNewUser, confirmPassword: event.target.value})}}
                          />
                        <Autocomplete
                          options = {['user', 'admin']}
                          disableClearable
                          defaultValue = 'user'
                          renderInput={(params) => <TextField {...params} label="Privilege" />}
                          onChange = {(event) => {setRegisterNewUser({...registerNewUser, privilege: event.target.value})}}
                        />
                        <Button sx = {{gridColumn: "3 / 4"}} onClick = {handleRegister}>Add User</Button>
                        {registerNewUserMsg&& <Alert hidden={registerNewUserMsg} severity={registerNewUserMsg.toLowerCase().includes('error') ? 'error' : 'success'}>{registerNewUserMsg}</Alert>}
                    </AccordionDetails>
                </Accordion>
                <h3>Update Password: </h3>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                      Change Password
                  </AccordionSummary>
                    <AccordionDetails sx = {{display: "grid", gridTemplateColumns: "repeat(3,1fr)", gap: "1rem"}}>
                        <TextField
                          required 
                          id = 'email'
                          label = 'Email'
                          onChange = {(event) => {setUpdatePwd({...updatePwd, email: event.target.value});}}
                        />
                        <TextField
                          required
                          type = 'password'
                          id = 'new password'
                          label = 'New Password'
                          onChange = {(event) => {setUpdatePwd({...updatePwd, newPassword: event.target.value});}}
                        />
                        <TextField
                          required 
                          type = 'password'
                          id = 'confirm password'
                          label = 'Confirm Password'
                          value = {updatePwd.confirmPassword}
                          onChange = {(event) => {setUpdatePwd({...updatePwd, confirmPassword: event.target.value});}}
                        />
                        {updatePwdMsg && <Alert hidden={updatePwdMsg} severity={updatePwdMsg.toLowerCase().includes('error') ? 'error' : 'success'}>{updatePwdMsg}</Alert>}
                        <Button sx = {{gridColumn: "3 / 4"}} onClick = {handleUpdatePassword}>Update Password</Button>
                    </AccordionDetails>
                </Accordion>
            </div>
            <h3>All Users: </h3>

            <Dialog open = {openConfirm} onClose={handleCloseConfirm}>
              <DialogTitle>Are you sure?</DialogTitle>
              <DialogActions>
                  <Button onClick={handleCloseConfirm}>Cancel</Button>
                  <Button onClick={handleDelete}>Delete</Button>
              </DialogActions>
            </Dialog>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((col, idx) => (
                            <TableCell  sx = {{fontWeight: "bold"}} className='header'>{col}</TableCell>
                        ))
                    }
                    {/* <TableCell>Change Privilege</TableCell> */}
                    <TableCell  sx = {{fontWeight: "bold"}}>Delete User</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                      <UserRow 
                        key={index} 
                        row={row} 
                        user={props.user}
                        handleOpenConfirm = {handleOpenConfirm}
                        handleChangeUser = {handleChangeUser}
                      />
                    ))
                  }
                  
                </TableBody>
              </Table>
            </TableContainer>
        </div>
  )
}

export default AdminDashboard