import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apis from '../../../api';
import InputForm from "../InputForm";

export default function SourcesInputForm(props) {
  const [selectedValues, setSelectedValues] = useState({});
  const [autofillOptions, setAutofillOptions] = useState([]);
  const navigate = useNavigate();

  const getSourceFormValues = async () => {
    try {
      const paramValue = new URLSearchParams(window.location.search).get("referenceshort");
      let res = await apis.GetFormOptions({table: 'sources', referenceshort: paramValue});
      let newAutofillOptions = res.data.options;
      //check if url has rowid = # field. If it does, fill in entries
      
      if (paramValue) { //if updating
        const temp = {}
        //console.log(newautofillOptions)s
        for(const el of newAutofillOptions){
          // console.log(el)
          temp[el.name] = el.start
        }
        temp['referenceshort'] = paramValue;
        setSelectedValues(temp)
      }
      
      //make display names for the columns
      const array = [
        'Reference',
        'Year of publication',
        'Reference Long',
        'DOI',
        'Comments',
        'URI',
      ];
      newAutofillOptions.map((item, index)=>{
        item.displayName = array[index]
      })
      setAutofillOptions(newAutofillOptions);
    } catch (error) {
      console.error("Error fetching distinct values:", error);
    }
  }

  useEffect(() =>{
    getSourceFormValues();
  }, [])
  
  const handleSubmit = async () => {
    const res = await apis.SubmitMeasurement({measurements: selectedValues, table: 'sources'});
    if (res.status === 200) {
      navigate('/sources');
      return;
    }
    return 'ERROR: Submission Failed';
  }
  
  
  return (
    <>
    {
      <InputForm 
        pageHeader = {"Sources Input Form"}
        autofillOptions = {autofillOptions} 
        selectedValues = {selectedValues} 
        setSelectedValues = {setSelectedValues}
        handleSubmit = {handleSubmit} 
      />
    }
    </>
  );  
}

