import React, { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Button} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import apis from '../../../api';
import InputForm from "../InputForm";

export default function DatasetInputForm(props) {
  const [selectedValues, setSelectedValues] = useState({});
  const [autofillOptions, setAutofillOptions] = useState([]);
  const [loading, setLoading] = useState(true)
  
  const navigate=useNavigate(); 

  const getDatasetFormValues = async () => {
    try {
      const paramValue = new URLSearchParams(window.location.search).get("rowid");
      let newAutofillOptions = await apis.GetFormOptions({table: 'dataset', row: paramValue})
      console.log(newAutofillOptions)
      newAutofillOptions = newAutofillOptions.data.options
      //check if url has rowid = # field. If it does, fill in entries
      
      if (paramValue) { //if updating
        const temp = {}
        for(const el of newAutofillOptions){
          temp[el.name] = el.start
        }
        temp['datasetid'] = paramValue
        setSelectedValues(temp)
      }
      
      //make display names for the columns
      const array = [
        'Reference used',
        'Study Site',
        'Species name in reference',
        'Vernacular name in reference',
        'Current Species name',
        'Nutrition',
        'Begin Year Study',
        'End Year Study',
        'Comment Dataset',
      ];
      newAutofillOptions.map((item, index)=>{
        item.displayName = array[index]
      })

      setAutofillOptions(newAutofillOptions);
      setLoading(false)
      console.log(newAutofillOptions)
    } catch (error) {
      console.error("Error fetching distinct values:", error);
    }
  };
  useEffect(() =>{
    getDatasetFormValues()
  }, []);

  const handleSubmit = async () => {
    const res = await apis.SubmitMeasurement({measurements: selectedValues, table: 'dataset'});
    if (res.status === 200) {
      navigate('/datasets');
      return;
    }
    return 'ERROR: Submission Failed';
  }
  
  
  return (
    <>
    {
      <InputForm pageHeader = {"Dataset Input Form"} autofillOptions = {autofillOptions} selectedValues = {selectedValues} setSelectedValues = {setSelectedValues}
      handleSubmit = {handleSubmit} />
    }
    </>
  );  
}

