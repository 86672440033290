import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Table,
  TableBody,
} from '@mui/material';
import FilterDialogRow from './FilterDialogRow'


export default function FilterDialog(props) {
  const handleClose=()=>{
    props.setOpen(false);
  }

  const handleAddFilter=()=>{
    let temp=[...props.filters];
    temp.push({column: props.cols[0]['field'], operator: '', value:''});
    props.setFilters(temp);
  }

  const handleRemoveAll=()=>{
    props.setFilters([]);
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
      maxWidth="md"
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Filter List</DialogTitle>
      <DialogContent dividers>
        <Table>
          <TableBody>
            {
              props.filters.map((filter, index) => (
                <FilterDialogRow 
                  cols={props.cols} 
                  setFilters={props.setFilters}
                  filters={props.filters}
                  filter={filter}
                  index={index} 
                  key={index}
                  rows = {props.rows}
                />
              ))
            }
        </TableBody>
        </Table>
      </DialogContent>

      <DialogActions>
        <Button color='warning' onClick={handleRemoveAll}>Remove All</Button>
        <div style={{flex: '1 0 0'}} />
        <Button onClick={handleAddFilter}>
          Add Filter
        </Button>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}