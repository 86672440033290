import React from 'react'
import PageView from '../../components/Home/PageView'
import apis from '../../api'

const Locations = (props) => {
  return (
        <PageView view={'locations'} func={apis.GetLocations}/>
  )
}

export default Locations