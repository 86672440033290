import PageView from '../../components/Home/PageView'
import apis from '../../api';
import { useState } from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const Sources = (props) => {
    const [openUpdate, setOpenUpdate] = useState(false); 
    const [inputVal, setInputVal] = useState(0);

    const handleOpenUpdate = () => {
        setOpenUpdate(true);
    }
    const handleCloseUpdate = () => {
        setOpenUpdate(false);
    }
    
    const handleUpdate = () => {
        setOpenUpdate(false);
        console.log(inputVal)
        window.open("/input/sources?referenceshort=" + inputVal, "_blank")
    }

    const inputBar= <div className="inputbar">
              
        <Button onClick = {handleOpenUpdate}>Update</Button>
        <Dialog open = {openUpdate} onClose={handleCloseUpdate}>
            <DialogTitle>Enter reference short for updation</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    onBlur = {(event)=>setInputVal(event.target.value)}
                    margin="dense"
                    id="measurement-update-id-form"
                    label="Reference Short"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseUpdate}>Cancel</Button>
                <Button onClick={handleUpdate}>Update</Button>
            </DialogActions>
        </Dialog>

        <Button onClick = {() =>{window.open("input/sources")}}>insert</Button>
    </div>

    return (
        <PageView view={'source'} func={apis.GetSources} inputBar = {inputBar}/>
    )
}

export default Sources;
